import React, {FC, InputHTMLAttributes} from 'react';
import {TextareaStyled} from './styled';

interface Props extends InputHTMLAttributes<HTMLElement> {
    name: string;
    onChange?: any;
    autoHeight?: boolean;
    error?: any;
}

const Textarea: FC<Props> = ({onChange, autoHeight = false, ...props}) => {
    const minRows = 4;
    const maxRows = 10;
    const textareaLineHeight = 18;
    const [rows, setRows] = React.useState(minRows);

    const handleHeight = (event: any) => {
        const previousRows = event.target.rows;
        event.target.rows = minRows; // reset number of rows in textarea
        const currentRows = ~~((event.target.scrollHeight - 35) / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        setRows(currentRows < 10 ? currentRows : 10);
    };

    return (
        <TextareaStyled
            rows={rows}
            onChange={(event: any)=>{
                if (autoHeight) handleHeight(event);
                if (onChange) onChange(event);
            }}
            {...props}
        />
    );
};

export default Textarea;
