import React, {FC, useEffect} from 'react';
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment, {Moment} from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import {defaultTheme} from "../../../themes/main";

interface Props {
    label?: string,
    value: any,
    onChange: any;
    disabled?: boolean;
}

const TimepickerMUI: FC<Props> = ({value, label = '', onChange, disabled = false, ...props}) => {
    const [val, setVal] = React.useState<Moment | null>(moment().tz('UTC').set({"hour": 12, "minute": 0}));

    useEffect(() => {
        if (value) setVal(value);
    }, [value]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
                label={label}
                value={val}
                disabled={disabled}
                onChange={(newValue) => {
                    setVal(newValue);
                    onChange(newValue);
                }}
                slotProps={{
                    textField: { size: 'small' },
                    layout: {
                        sx: {
                            ul: {
                                // 'overflow-y': 'scroll',
                                'scrollbar-width': 'thin',
                                'scrollbar-color': '#81b8c0 #f0f0f0',
                                '&::-webkit-scrollbar': {
                                    'width': '7px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    'background': '#f0f0f0',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    'background-color': '#76AFB8',
                                }
                            },
                        },
                    }
                }}
                sx={{
                    "& .MuiOutlinedInput-input": {
                        height: '24px',
                        fontSize: 14,
                        fontWeight: 400,
                        color: '#677477',
                        backgroundColor: "white",
                    },
                    ".MuiOutlinedInput-input.Mui-disabled": {
                        backgroundColor: `${defaultTheme.disabledBackgroundColor} !important`,
                    },
                }}
                {...props}
            />
        </LocalizationProvider>
    );
};

export default TimepickerMUI;
