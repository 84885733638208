import styled from "styled-components";
import {Checkbox, Textarea, TimepickerMUI} from "../../../controls";
import DatepickerMUI from "../../../controls/DatepickerMUI/DatepickerMUI";
import SingleSelect from "../../../controls/SingleSelect/SingleSelect";
import {RadioGroup} from "@mui/material";

export const MessageWrapper = styled.div<{provided: any, snapshot: any}>`
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background-color: ${(props) => props?.snapshot?.isDragging ? 'rgb(240, 240, 240)' : 'rgb(250, 250, 250)'};
  border: ${(props) => props?.snapshot?.isDragging ? '2px dashed grey' : '1px solid rgba(0, 0, 0, 0.20)'};
  box-shadow: ${(props) => props?.snapshot?.isDragging ? '15px 15px rgb(200, 200, 200, 0.6)' : 'none'};
  
  gap: 30px;
  min-width: 740px;
  user-select:none;
  @media screen and (max-width: 880px) {
    flex-direction: column;
    gap: 0;
    min-width: 250px;
  }
`;

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  min-width: 210px;
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MessageTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.greenColor};
`;

export const MessageLabel = styled.label`
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 15px;
`;

export const DragHandle = styled.div`
  position: relative;
  height: 15px;
`;

export const DragDropImg = styled.img`
  position: absolute;
  margin-left: auto;
  padding: 15px;
  top: -15px;
  right: -15px;
  @media screen and (max-width: 880px){
    visibility: hidden;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const TextareaStyled = styled(Textarea)`
  margin-bottom: 20px;
  width: 100%;
  resize: none;
  min-width: 100px;
`;

export const DatepickerMUIStyled = styled(DatepickerMUI)`
  width: 150px;
`;

export const TimepickerMUIStyled = styled(TimepickerMUI)`
  width: 150px;
`;

export const SingleSelectStyled = styled(SingleSelect)`
  width: 150px;
`;

export const Radios = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 880px){
    flex-direction: column !important;
    gap: 0;
  }
`;

export const TimeSettings = styled.div`
  display: flex;
  gap: 15px;

  @media screen and (max-width: 880px){
    flex-direction: column;
    gap: 10px;
  }
`;

