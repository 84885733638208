import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
  font-weight: 700;
  font-size: 26px;
  white-space: nowrap;
  height: 72px;
`;

export const Desc = styled.div`
  color: ${(props) => props.theme.blackTextColor};
  margin-bottom: 60px;
  text-align: center;
`;

export const ButtonLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    text-decoration: none;
  }
`;
