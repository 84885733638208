import React, {FC, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchLogout, fetchUser} from "../../../store/auth/authSlice";
import {ROUTES} from "../../../router/routes";
import {
    Img,
    StyledHeader,
    UlStyled,
    StyledLink,
    Menu,
    MenuHeader,
    MenuList,
    MenuH2Styled,
    CloseImg,
    Hamburger, NotificationsCounter,
} from './styled';
import logo from '../../../assets/images/j3tech_aftercare_logo.svg';
import hamburger from '../../../assets/images/icons/hamburger.svg';
import menuClose from '../../../assets/images/icons/menuClose.svg';
import {SSO_LOGOUT} from "../../../constants/api";

const Header: FC = () => {
    const dispatch = useAppDispatch();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split('/');
    const {user} = useAppSelector(state => state.auth);

    useEffect(() => {
        dispatch(fetchUser());
    }, []);

    const handleLogout = () => {
        dispatch(fetchLogout());
        window.open(SSO_LOGOUT, "_self");
    };

    const unreadDialogsCount = user?.profile?.unread_dialogs_count || 0;

    return (
        <StyledHeader>
            <a href={'/sms-activity'}>
                <Img src={logo}/>
            </a>
            <UlStyled>
                <StyledLink
                    to={ROUTES.SMS_PANEL}
                    $isActive={splitLocation[1].indexOf('sms-panel') >= 0}
                >
                    SMS Panel
                    {!!unreadDialogsCount && (
                        <NotificationsCounter>{unreadDialogsCount > 9 ? '9+' : unreadDialogsCount}</NotificationsCounter>
                    )}
                </StyledLink>
                <StyledLink
                    to={ROUTES.SMS_ACTIVITY}
                    $isActive={splitLocation[1].indexOf('sms-activity') >= 0}
                >
                    SMS Activity
                </StyledLink>
                <StyledLink
                    to={ROUTES.CUSTOMERS}
                    $isActive={splitLocation[1].indexOf('customer') >= 0}
                >
                    Customers
                </StyledLink>
                <StyledLink
                    to={ROUTES.IMPORT}
                    $isActive={splitLocation[1].indexOf('import') >= 0}
                >
                    Data Import
                </StyledLink>

            </UlStyled>
            <Hamburger
                onClick={()=>setMenuOpen(!isMenuOpen)}
                src={hamburger}
            />

            {isMenuOpen &&
                <Menu>
                    <MenuHeader>
                        <MenuH2Styled>Menu</MenuH2Styled>
                        <CloseImg
                            onClick={()=>setMenuOpen(!isMenuOpen)}
                            src={menuClose}
                        />
                    </MenuHeader>
                    <MenuList>
                        <StyledLink
                            to={ROUTES.SMS_PANEL}
                            $isActive={splitLocation[1].indexOf('sms-panel') >= 0}
                            onClick={()=>setMenuOpen(false)}
                        >
                            SMS Panel
                        </StyledLink>
                        <StyledLink
                            to={ROUTES.SMS_ACTIVITY}
                            $isActive={splitLocation[1].indexOf('sms-activity') >= 0}
                            onClick={()=>setMenuOpen(false)}
                        >
                            SMS Activity
                        </StyledLink>
                        <StyledLink
                            to={ROUTES.CUSTOMERS}
                            $isActive={splitLocation[1].indexOf('customer') >= 0}
                            onClick={()=>setMenuOpen(false)}
                        >
                            Customers
                        </StyledLink>
                        <StyledLink
                            to={ROUTES.IMPORT}
                            $isActive={splitLocation[1].indexOf('import') >= 0}
                            onClick={()=>setMenuOpen(false)}
                        >
                            Data Import
                        </StyledLink>
                        <StyledLink
                            to={ROUTES.CAMPAIGNS}
                            $isActive={splitLocation[1].indexOf('campaign') >= 0}
                            onClick={()=>setMenuOpen(false)}
                        >
                            Campaign Management
                        </StyledLink>
                        <StyledLink
                            to={ROUTES.ACCOUNT_MANAGEMENT}
                            $isActive={splitLocation[1].indexOf('global-account-management') >= 0}
                            onClick={()=>setMenuOpen(false)}
                        >
                            Global Account Management
                        </StyledLink>
                        <StyledLink
                            onClick={()=>{
                                setMenuOpen(false);
                                handleLogout();
                            }}
                        >
                            Logout
                        </StyledLink>
                    </MenuList>
                </Menu>
            }
        </StyledHeader>
    );
};

export default Header;
