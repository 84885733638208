import React, {FC} from 'react';
import {PageContent, PageContentContainer, PageHeader, Spinner} from "../../common";
import {Button} from "../../controls";
import {ButtonLink, Desc, PageTitle, Wrapper} from "./styled";
import {useNavigate} from "react-router-dom";
import {APP_URI, SSO_URI} from "../../../constants/api";
import {useAppSelector} from "../../../hooks/global";

const GlobalAccountManagement: FC = () => {
    const navigate = useNavigate();
    const {user} = useAppSelector(state => state.auth);

    // const navToSSO = () => {
    //     console.log('Access Global Account Management Click');
    //     openInNewTab();
    // }

    // const openInNewTab = () => {
    //     // window.location.replace(`${SSO_URI}/myAccountDetails/${user.profile.company_id}?postRedirectUri=${APP_URI}/global-account-management`);
    //     window.open(`${SSO_URI}/myAccountDetails/${user.profile.company_id}?postRedirectUri=${APP_URI}/global-account-management`, "_blank", "noreferrer");
    // };

    return (
        <PageContentContainer>
            <Spinner isLoading={false}/>
            <PageHeader>
                <PageTitle>Global Account Management</PageTitle>
            </PageHeader>
            <PageContent>
                <Wrapper>
                    <Desc>With Global Account Management, you gain the power to oversee various roles within your team, including Company Administrators,
                        Regional Managers, Location Managers, and Arrangers. Moreover, you can conveniently handle your Account Details through a user-friendly interface,
                        streamlining your management tasks into one centralized hub!
                    </Desc>
                    {/*<ButtonLink href={`${SSO_URI}/myAccountDetails/${user?.profile.company_id}?postRedirectUri=${APP_URI}/global-account-management`} target={'_blank'}>*/}
                    <ButtonLink href={`https://sso-dev.j3techsolution.com/login-sso?redirectUri=https%3A%2F%2Fsso-dev.j3techsolution.com%2FmyAccountDetails&postRedirectUri=https%3A%2F%2Fdev.smsaftercare.dnmiss.com%2Fglobal-account-management&platformId=5`} target={'_blank'}>
                        <Button
                            title={'Access Global Account Management'}
                            // onClick={navToSSO}
                        />
                    </ButtonLink>

                </Wrapper>
            </PageContent>
        </PageContentContainer>
    );
};

export default GlobalAccountManagement;
