import styled from "styled-components";
import {Button, MultiSelect, SingleSelect} from "../../controls";
import Input from "../../controls/Input/Input";
import {Error} from "../../controls/Input/styled";

export const PageGroup = styled.div`
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.blackTextColor};
`;

export const BtnWrapper = styled.div`
  display: flex;
  margin: 18px 32px 18px 32px;
  justify-content: space-between;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 650px;
  width: 100%;
  user-select:none;
  
  @media screen and (max-width: 1150px) {
    min-width: 0;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 26px;
  white-space: nowrap;
`;

export const CampaignName = styled.div`
  margin-top: 15px;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.greenColor};
`;

export const FormGroup = styled.div`
  margin-top: 50px;
`;

export const FormGroupRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
`;

export const FormGroupCol = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (max-width: 880px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FormLabel = styled.label`
  width: 100px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
  white-space: nowrap;
  text-align: right;

  @media screen and (max-width: 880px) {
    text-align: left;
    margin-bottom: 5px;
  }
`;

export const FormInput = styled(Input)`
  ${Error} {
    position: absolute;
  }
`;

export const FormSingleSelect = styled(SingleSelect)`
  width: 250px;
  min-width: 250px;
`;

export const FormMultiSelect = styled(MultiSelect)`
  width: 250px;
  min-width: 250px;
`;

export const FormButtons = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 20px
`;

export const FormButton = styled(Button)`
  margin-top: -30px;
`;

export const AddNewMessage = styled(Button)`

`;

export const DraggableWrapper = styled.div`
`;


