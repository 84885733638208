import styled from "styled-components";
import {Link} from 'react-router-dom';
import MessagesCircle from '../../../assets/images/icons/messages_circle.png';
import MessagesCircleActive from '../../../assets/images/icons/messages_circle_active.png';

export const StyledHeader = styled.header`
  height: 60px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.whiteColor};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`;

export const Img = styled.img`
  margin-left: 54px;
  
  @media screen and (max-width: 670px){
    margin-left: 15px;
    margin-top: 15px;
    width: 300px;
  }
`;

export const NotificationsCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-left: 10px;
  background-image: url(${MessagesCircle});
  font-size: 10px;
  font-weight: 500;
`;

export const StyledLink:any = styled(Link)<{$isActive?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 25px 8px 25px;
  min-width: 120px;
  text-align: center;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: ${(props) => props.theme.greyTextColor};
  ${(props) => props.$isActive && {
    backgroundColor: props.theme.greenColor,
    color: props.theme.whiteColor,
    fontWeight: 700,
  }}

  ${NotificationsCounter} {
    background-image: url(${(props) => props.$isActive ? MessagesCircleActive : MessagesCircle});  
  }
`;

export const UlStyled = styled.ul`
  display: flex;
  padding: 0;
  list-style-type: none;
  
  @media screen and (max-width: 1152px){
    ${StyledLink} {
      padding: 8px 10px 8px 10px;
    }  
  }
  
  @media screen and (max-width: 1030px){
    display: none;
  }
`;

export const Hamburger = styled.img`
  margin-right: 25px;
  &:hover {
    scale: 105%;
  }
`;

export const Menu = styled.div`
  position: absolute;
  width: 320px;
  min-height: 300px;
  background-color: ${(props) => props.theme.greenColor};
  z-index: 1;
  top: 0;
  right: 0;
  border-bottom-left-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.65);
`;

export const MenuHeader = styled.div`
  display: flex;
  padding-top: 16px;
  padding-bottom: 40px;
  justify-content: space-between;
  align-items: center;
`;

export const CloseImg = styled.img`
  margin-right: 20px;
  
  &:hover {
    scale: 105%;
  }
`;

export const MenuH2Styled = styled.h2`
  display: flex;
  padding: 0;
  margin: 0 0 0 35px;
  color: ${(props) => props.theme.blackTextColor};
  font-size: 26px;
  font-weight: 700;
`;

export const MenuList = styled.div`
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${StyledLink} {
    text-align: left;
    margin: 0;
    padding: 25px 25px 25px 0;
    
    &:hover {
      transform:scale(1.2);
      transform-origin:center center;
      font-weight: 800;
    }
  }
`;