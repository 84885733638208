import {createSlice, createAsyncThunk, configureStore} from '@reduxjs/toolkit';
import { format } from 'date-fns';
import api from '../../service/api';
import {CustomerProps} from "../../types";
import {showSuccessAlert} from '../alert/alertSlice'

export const fetchSearch = createAsyncThunk('customers/search', async (params: any) => {
    const {alias, query} = params;
    const response = await api.get(`customers/search/${alias}`, {params: {query: query}});
    return response.data;
});

export const fetchCustomers = createAsyncThunk('customers/list', async (params: any) => {
    const response = await api.get('customers/list', { params });
    return response.data;
});

export const fetchCustomerById = createAsyncThunk('customer/get', async (id: string) => {
    const response = await api.get(`customers/${id}/get`);
    return response.data;
});

export const fetchUpdateCustomer = createAsyncThunk('customer/update', async (params: any, thunkAPI) => {
    const {id, data, cb} = params;
    try {
        const response = await api.post(`customers/${id}/update`, convertDataToServer(data))
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message, cb}));
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }}
)

export const fetchDeleteCustomer = createAsyncThunk('customer/delete', async (id: number) => {
    const response = await api.post(`customers/${id}/delete`);
    return response.data;
});

interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
    account: string,
    customer: string,
    director: string,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface CustomerState {
    customers: Array<object>;
    search: {
        customers: Array<object>;
        accounts: Array<object>;
        directors: Array<object>;
    };
    customer: CustomerProps | null
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
    isAutocompleteLoading: boolean;
}

const initialState: CustomerState = {
    customers: [],
    customer: null,
    search: {
        customers: [],
        accounts: [],
        directors: [],
    },
    listFilters: {
        order_by: 'name',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
        account: '',
        customer: '',
        director: '',
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
    isAutocompleteLoading: false,
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
        resetAccounts: (state) => {
            state.search.accounts = initialState.search.accounts;
        },
        resetCustomers: (state) => {
            state.search.customers = initialState.search.customers;
        },
        resetDirectors: (state) => {
            state.search.directors = initialState.search.directors;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSearch.pending, state => {
                state.isAutocompleteLoading = true;
            })
            .addCase(fetchSearch.fulfilled, (state: any, action: any) => {
                const {resource} = action.payload.data;
                const {alias} = action.meta.arg;
                if (alias === 'customer') state.search.customers = resource;
                else if (alias === 'account') state.search.accounts = resource;
                else if (alias === 'director') state.search.directors = resource;
                state.isAutocompleteLoading = false;
            })
            .addCase(fetchSearch.rejected, state => {
                state.isAutocompleteLoading = false;
            })
            .addCase(fetchCustomers.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCustomers.fulfilled, (state: any, action: any) => {
                const {data, current_page, total, last_page, ipp, per_page, from, to} = action.payload.data.resource;
                state.customers = data;
                state.pagination = {
                    ...state.pagination,
                    current_page: current_page,
                    total: total,
                    last_page: last_page,
                    ipp: ipp,
                    page: current_page,
                    per_page: per_page,
                    from: from,
                    to: to,
                }
                state.isLoading = false;
                state.isAutocompleteLoading = false;
            })
            .addCase(fetchCustomers.rejected, state => {
                state.isLoading = false;
                state.isAutocompleteLoading = false;
            })
            .addCase(fetchCustomerById.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCustomerById.fulfilled, (state: any, action: any) => {
                state.customer = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchCustomerById.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateCustomer.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateCustomer.fulfilled, (state: any, action: any) => {
                state.customer = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchUpdateCustomer.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteCustomer.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDeleteCustomer.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
            })
            .addCase(fetchDeleteCustomer.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

const convertDataToServer = (data: any) => {
    const ret: any = {
        customer: {
            name: data.customer_name,
            language: data.language,
        },
        deceased: {
            name: data.deceased_name,
            // company: data.company,
            // company_location: data.company_location,
            director: data.director,
            case_type: data.case_type,
            dob: data.dob ? format(data.dob, "yyyy-MM-dd") : null,
            dod: data.dod ? format(data.dod, "yyyy-MM-dd") : null,
            funeral_date: data.funeral_date ? format(data.funeral_date, "yyyy-MM-dd") : null,
            relationship: data.relationship,
        },
        address: {
            address: data.address,
            address2: data.address2,
            city: data.city,
            state_id: data.state_id,
            zip: data.zip,
            apartment: data.apartment,
            cell_phone: data.cell_phone,
            email: data.email,
        }
    };

    return ret;
}

export const { setFilterParams, resetFilters, resetAccounts, resetCustomers, resetDirectors } = customerSlice.actions;

export default customerSlice.reducer;
